import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const BuscaRede = async (id_rede) => {
  const apiUrl = process.env.REACT_APP_API_BASE_29;

  try {
    const response = await api.get(`${apiUrl}${id_rede}`);
    console.log(response.data); // Verifique a estrutura exata da resposta aqui
    
    if (response.data) {
      secureStorage.setItem("datarede", JSON.stringify(response.data));
      return response.data; // Retorna os dados encontrados
    } else {
      console.error("Erro ao buscar cadastros. Veja o console para mais detalhes.");
      return [];
    }
  } catch (error) {
    console.error("Erro ao buscar cadastros. Veja o console para mais detalhes.", error);
    return [];
  }
};

export default BuscaRede;
