import styled from "styled-components";

export const PageContainer = styled.div`
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //min-height: 100vh;
  background-color: #fff;
`;

export const HeaderContainer = styled.div`
  //width: 100%;
  //position: fixed;
  //top: 0;
  background-color: #ffffff;
  //padding: 10px;
  //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  //display: flex;
  //justify-content: center;
  //z-index: 10;
`;

export const Content = styled.div`
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  //height: 55vh;
  padding-top: 60px; /* Para ajustar o espaço abaixo do Header */
  //text-align: center;
`;
