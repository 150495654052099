import React, { useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import BuscaRede from '../../services/Rede/BuscaRede';
import secureStorage from '../../utils/SecureStorage';

const GeraPdfAtendimento = ({ dados, cliente }) => {
  const [redeData, setRedeData] = useState(null);
  const idRede = secureStorage.getItem("id_r");

  // Busca as informações da rede quando o componente for montado
  useEffect(() => {
    const fetchRedeData = async () => {
      if (idRede) {
        try {
          const rede = await BuscaRede(idRede);
          setRedeData(rede);
        } catch (error) {
          console.error("Erro ao buscar cadastros. Veja o console para mais detalhes.", error);
        }
      }
    };

    fetchRedeData();
  }, [idRede]);

  const generatePDF = () => {
    if (!redeData) {
      console.error("Dados da rede não disponíveis.");
      return;
    }

    const doc = new jsPDF('p', 'pt', 'a4');
    const pageHeight = doc.internal.pageSize.height;

    // Função para adicionar o rodapé em cada página
    const addFooter = () => {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text("Gerado por MarketDo", 300, pageHeight - 20, { align: "center" });
      }
    };

    // Adicionando título
    doc.setFontSize(18);
    doc.text("Relatório de Atendimento", 300, 40, { align: "center" });

    // Adicionando informações principais em tabela
    doc.setFontSize(12);
    doc.autoTable({
      startY: 60,
      head: [['Atendimento', 'Informação']],
      body: [
        ['ID Atendimento', dados.id || "ID não disponível"],
        ['Nome Enpresa', redeData.nome_fantasia || "Nome não disponível"],
        ['CPF/CNPJ', redeData.cpf_cnpj || "CPF/CNPJ não disponível"],
        [
          'Endereço',
          redeData.endereco
            ? `${redeData.endereco.rua}, ${redeData.endereco.numero}, ${redeData.endereco.bairro}, ${redeData.endereco.cidade}-${redeData.endereco.estado}`
            : "Endereço não disponível",
        ],
        ['Data', formatDate(dados.data)],
        ['Colaborador', dados.colaborador?.nome || "Nome não disponível"],
      ],
      styles: { overflow: 'linebreak', cellPadding: 5, fontSize: 10, lineWidth: 0.5, lineColor: [0, 0, 0] },
      headStyles: { fillColor: [200, 200, 200], textColor: 0, fontStyle: 'bold', lineWidth: 0.5, lineColor: [0, 0, 0] },
      alternateRowStyles: { fillColor: [245, 245, 245] },
    });

    // Adicionando informações do cliente em tabela separada
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 20,
      head: [['Cliente', 'Informação']],
      body: [
        ['Nome', cliente.nome || "Nome não disponível"],
        ['CPF/CNPJ', cliente.cpf_cnpj || "CPF/CNPJ não disponível"],
        [
          'Endereço',
          cliente.endereco
            ? `${cliente.endereco.rua}, ${cliente.endereco.numero}, ${cliente.endereco.bairro}, ${cliente.endereco.cidade}-${cliente.endereco.estado}`
            : "Endereço não disponível",
        ],
      ],
      styles: { overflow: 'linebreak', cellPadding: 5, fontSize: 10, lineWidth: 0.5, lineColor: [0, 0, 0] },
      headStyles: { fillColor: [200, 200, 200], textColor: 0, fontStyle: 'bold', lineWidth: 0.5, lineColor: [0, 0, 0] },
      alternateRowStyles: { fillColor: [245, 245, 245] },
    });

    // Adicionando informações do estabelecimento em tabela separada
    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 20,
      head: [['Estabelecimento', 'Informação']],
      body: [
        ['Nome Fantasia', dados.estabelecimento?.nome_fantasia || "Nome não disponível"],
        [
          'Endereço',
          dados.estabelecimento?.endereco
            ? `${dados.estabelecimento.endereco.rua}, ${dados.estabelecimento.endereco.numero}, ${dados.estabelecimento.endereco.bairro}, ${dados.estabelecimento.endereco.cidade}-${dados.estabelecimento.endereco.estado}`
            : "Endereço não disponível",
        ],
      ],
      styles: { overflow: 'linebreak', cellPadding: 5, fontSize: 10, lineWidth: 0.5, lineColor: [0, 0, 0] },
      headStyles: { fillColor: [200, 200, 200], textColor: 0, fontStyle: 'bold', lineWidth: 0.5, lineColor: [0, 0, 0] },
      alternateRowStyles: { fillColor: [245, 245, 245] },
    });

    // Adicionando produtos com fotos lado a lado
    let yOffset = doc.lastAutoTable.finalY + 20;
    cliente.produtos.forEach((produto, index) => {
      if (yOffset + 150 > pageHeight) {
        doc.addPage();
        yOffset = 40;
      }

      // Adicionando informações do produto em tabela
      doc.autoTable({
        startY: yOffset,
        head: [['Produto', 'Marca', 'Descrição', 'Ruptura', 'Não Atendido', 'Observação']],
        body: [
          [
            `Produto ${index + 1}`,
            produto.marca || "Não informado",
            produto.descricao || "Não informado",
            produto.ruptura ? "Sim" : "Não",
            produto.naoAtendido ? "Sim" : "Não",
            produto.rupturaobservacao || produto.naoAtendidoobservacao ||"Sem observação",
          ],
        ],
        styles: { overflow: 'linebreak', cellPadding: 5, fontSize: 10, lineWidth: 0.5, lineColor: [0, 0, 0] },
        headStyles: { fillColor: [200, 200, 200], textColor: 0, fontStyle: 'bold', lineWidth: 0.5, lineColor: [0, 0, 0] },
        alternateRowStyles: { fillColor: [245, 245, 245] },
      });


      // Ajustar o yOffset para que o próximo conteúdo não sobreponha as imagens
      yOffset = doc.lastAutoTable.finalY + 20;

      if (produto.fotos && produto.fotos.length > 0) {
        let xOffset = 40; // Posição horizontal inicial
        const maxWidth = 500; // Largura máxima para as fotos antes de quebrar linha

        produto.fotos.forEach((foto, fotoIndex) => {
          if (yOffset + 120 > pageHeight) {
            doc.addPage();
            yOffset = 40;
            xOffset = 40; // Reinicia a posição horizontal ao adicionar uma nova página
          }

          if (xOffset + 110 > maxWidth) {
            // Quebra para a linha abaixo se a próxima imagem não couber na linha atual
            yOffset += 120;
            xOffset = 40;
          }

          const imgData = `data:image/png;base64,${foto}`;
          doc.addImage(imgData, 'PNG', xOffset, yOffset, 100, 100);

          xOffset += 110; // Move para a próxima posição horizontal para a próxima imagem
        });

        yOffset += 120; // Adiciona um espaço extra menor após as fotos para o próximo produto
      }

      yOffset += 10;
    });



    // Adicionando o rodapé em cada página
    addFooter();

    // Gerar PDF para download
    const fileName = `Cliente_${cliente.nome}_${new Date().toISOString().split('T')[0]}.pdf`;
    doc.save(fileName);
  };

  return (
    <button onClick={generatePDF}>Gerar PDF</button>
  );
};

// Ajuste na função para lidar com problemas de fuso horário e formato ISO
const formatDate = (dateObj) => {
  if (dateObj && dateObj.$date) {
    // Extrai os valores de ano, mês e dia diretamente da string ISO
    const date = new Date(dateObj.$date);

    const year = date.getUTCFullYear();
    const month = date.getUTCMonth(); // Mês é baseado em 0, então precisa somar +1
    const day = date.getUTCDate();

    // Formata a data para o padrão dia/mês/ano
    return `${day.toString().padStart(2, '0')} de ${monthToPortuguese(month)} de ${year}`;
  }
  return 'Data inválida';
};

// Função auxiliar para retornar o mês em português
const monthToPortuguese = (month) => {
  const months = [
    'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
    'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
  ];
  return months[month];
};


export default GeraPdfAtendimento;