import React, { useState, useEffect, useCallback } from "react";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";
import secureStorage from '../../utils/SecureStorage';

// Nossos Components  
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import iconDefault from "../../assets/Default.png";
import iconIniciado from "../../assets/Iniciado.png";
import iconTerminado from "../../assets/Terminado.png";
import iconAtrasada from "../../assets/Atrasada.png";
import VisitaServicesBusca from "../../services/Visita/VisitaServicesBusca";

const statusEnum = {
  0: "Nao iniciado",
  1: "Iniciado",
  2: "Concluido",
  3: "Atrasado"
};

const FilterCard = ({ title, actived }) => {
  return (
    <S.FilterCardContainer actived={actived.toString()}>
      <span>{title}</span>
    </S.FilterCardContainer>
  );
};

const Quadrodeatendimentos = () => {
  const [filterActived, setFilterActived] = useState("allVisita");
  const [visitas, setVisitas] = useState([]);
  const [idrede] = useState(secureStorage.getItem("id_r"));
  const [name, setName] = useState("");
  const [name2, setName2] = useState("");
  const [name3, setName3] = useState("");
  const navigate = useNavigate();

  const loadVisitas = useCallback(async () => {
    try {
      const data = await VisitaServicesBusca.loadregistros(idrede, filterActived);
      setVisitas(data);
    } catch (error) {
      console.error("Erro ao buscar atendimentos:", error);
      alert("Erro ao buscar atendimentos. Veja o console para mais detalhes.");
    }
  }, [idrede, filterActived]);

  useEffect(() => {
    loadVisitas();
  }, [loadVisitas]);

  const filterColaborador = (e) => {
    const keyword = e.target.value;
    setName(keyword);
    if (keyword !== "") {
      const results = visitas.filter((user) => user.colaborador.nome.toLowerCase().startsWith(keyword.toLowerCase()));
      setVisitas(results);
    } else {
      loadVisitas();
    }
  };

  const filterEstabelecimento = (e) => {
    const keyword = e.target.value;
    setName2(keyword);
    if (keyword !== "") {
      const results = visitas.filter((user) => user.estabelecimento.nome_fantasia.toLowerCase().startsWith(keyword.toLowerCase()));
      setVisitas(results);
    } else {
      loadVisitas();
    }
  };

  const filterStatus = (e) => {
    const keyword = e.target.value;
    setName3(keyword);
    if (keyword !== "") {
      const results = visitas.filter((user) => statusEnum[user.status].toLowerCase().startsWith(keyword.toLowerCase()));
      setVisitas(results);
    } else {
      loadVisitas();
    }
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  const formatDate = (dateObj) => {
    const date = parseISO(dateObj.$date.split('T')[0]);
    return format(date, "dd-MM-yyyy");
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "0":
      case 0:
        return iconDefault;
      case "1":
      case 1:
        return iconIniciado;
      case "2":
      case 2:
        return iconTerminado;
      case "3":
      case 3:
        return iconAtrasada;
      default:
        return iconDefault; // ou um ícone padrão
    }
  };

  const handleEditClick = (id) => {
    navigate(`/Atendimento/${id}/editar`);
  };

  const handleViewClick = (id) => {
    navigate(`/Atendimento/${id}`);
  };

  return (
    <S.Container>
      <Header />
      <S.FilterArea>
        <button type="button" onClick={() => setFilterActived("allVisita")}>
          <FilterCard title="Todos" actived={filterActived === "allVisita"} />
        </button>
        <button type="button" onClick={() => setFilterActived("today")}>
          <FilterCard title="Hoje" actived={filterActived === "today"} />
        </button>
        <button type="button" onClick={() => setFilterActived("week")}>
          <FilterCard title="Semana" actived={filterActived === "week"} />
        </button>
        <button type="button" onClick={() => setFilterActived("month")}>
          <FilterCard title="Mês" actived={filterActived === "month"} />
        </button>
        <button type="button" onClick={() => setFilterActived("year")}>
          <FilterCard title="Ano" actived={filterActived === "year"} />
        </button>
      </S.FilterArea>
      <S.Title>
        <h3>{filterActived === "late" ? "ATENDIMENTOS ATRASADOS" : "ATENDIMENTOS"}</h3>
      </S.Title>
      <S.Title2>
        <a href="#CF0505">{"NÃO INICIADO"}</a>
        <img src={iconDefault} alt="Nao iniciado" />
        <a href="#CF0505">{"INICIADO"}</a>
        <img src={iconIniciado} alt="Iniciado" />
        <a href="#CF0505">{"CONCLUÍDO"}</a>
        <img src={iconTerminado} alt="Concluído" />
        <a href="#CF0505">{"ATRASADO"}</a>
        <img src={iconAtrasada} alt="Atrasado" />
      </S.Title2>
      <S.Content2>
        <input type="search" value={name3} onChange={filterStatus} className="input" placeholder="Status" />
        <input type="search" value={name} onChange={filterColaborador} className="input" placeholder="Colaborador" />
        <input type="search" value={name2} onChange={filterEstabelecimento} className="input" placeholder="Estabelecimento" />
      </S.Content2>
      <S.Content>
        {visitas
          .sort((a, b) => {
            // Faz parsing da data usando parseISO para garantir que está no formato correto
            const dateA = parseISO(a.data.$date);
            const dateB = parseISO(b.data.$date);
            return dateA.getTime() - dateB.getTime(); // Ordena pela data em ordem crescente
          })
          .map((t, index) => {
            const formattedDate = formatDate(t.data);
            return (
              <S.VisitaCard key={`${t._id.$oid}-${index}`} type={parseInt(t.status)}>
                <S.VisitaCardTitle>{`Tarefa: ${t._id.$oid}`}</S.VisitaCardTitle>
                <S.VisitaCardTitle>{t.estabelecimento.nome_fantasia}</S.VisitaCardTitle>
                <S.VisitaCardParagraph>Colaborador: {t.colaborador.nome}</S.VisitaCardParagraph>
                <S.VisitaCardParagraph>
                  Cliente: {t.clientes.map((cliente, i) => (
                    <span key={i}>{cliente.nome}{i < t.clientes.length - 1 ? ', ' : ''}</span>
                  ))}
                </S.VisitaCardParagraph>
                <S.VisitaCardParagraph>Status: <S.VisitaCardIcon src={getStatusIcon(parseInt(t.status))} alt={statusEnum[t.status]} /></S.VisitaCardParagraph>
                <S.VisitaCardParagraph>Data: {formattedDate}</S.VisitaCardParagraph>
                <S.ButtonsContainer>
                  {t.status !== "2" && secureStorage.getItem("TipoCockpit") !== 1 && (
                    <S.EditButton onClick={() => handleEditClick(t._id.$oid)}>Editar</S.EditButton>
                  )}
                  <S.ViewButton onClick={() => handleViewClick(t._id.$oid)}>Visualizar</S.ViewButton>
                </S.ButtonsContainer>
              </S.VisitaCard>
            );
          })}
      </S.Content>

      <Footer />
    </S.Container>
  );
};

export default Quadrodeatendimentos;
