import styled from "styled-components";

// Contêiner da página
export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Garante que a página ocupe toda a altura da tela */
  width: 100%;
  background-color: #fff; /* Fundo branco */
`;

// Cabeçalho
export const HeaderContainer = styled.header`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  z-index: 10;

  display: flex;
  justify-content: center; /* Centraliza os itens do cabeçalho */
  align-items: center;
  text-align: center;

  h1 {
    font-size: 2rem;
    color: #e63946; /* Cor vermelha */
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    color: #666666; /* Cinza claro */
  }
`;

// Conteúdo principal
export const Content = styled.main`
  flex: 1; /* Faz o Content ocupar o espaço restante */
  display: flex;
  flex-direction: column; /* Permite que o conteúdo seja empilhado verticalmente */
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: #f9f9f9;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

// Botão do cabeçalho
export const HeaderButton = styled.a`
  text-decoration: none;
  color: #ffffff; /* Texto branco */
  font-size: 1rem;
  font-weight: bold;
  margin: 0 10px;
  padding: 10px 20px;
  border-radius: 8px; /* Bordas arredondadas */
  background-color: #e63946; /* Fundo vermelho */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: all 0.3s ease;

  &:hover {
    background-color: #c72d3f; /* Fundo mais escuro no hover */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Efeito elevado */
    transform: translateY(-2px); /* Move o botão para cima */
  }

  &:focus {
    outline: 2px dashed #e63946;
    outline-offset: 4px;
  }
`;

// Seção de benefícios
export const BenefitsSection = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  text-align: center;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

export const BenefitItem = styled.div`
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: scale(1.05);
    background: #efefef;
  }

  .benefit-icon {
    font-size: 3rem;
    margin-bottom: 10px;
    color: #e63946;
  }

  .benefit-title {
    font-size: 1.4rem;
    font-weight: bold;
    color: #666666;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    color: #333333;
  }
`;

// Seção de chamada para ação
export const CtaSection = styled.section`
  text-align: center;
  padding: 20px;
  background-color: #ffffff;

  p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #666666;
  }

  .cta-button {
    text-decoration: none;
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #e63946;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
      background-color: #c72d3f;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
      transform: translateY(-2px);
    }

    &:focus {
      outline: 2px dashed #e63946;
      outline-offset: 4px;
    }
  }
`;
