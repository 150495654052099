import React from 'react';
import * as S from './styles';
import { FormattedMessage } from 'react-intl';

function Footer_login() {
  return (
    <S.Container>
      <S.SpanCenterAlign>
        <FormattedMessage id="footer.slogan" defaultMessage="MarketDo - Facilitando seu dia!" />
      </S.SpanCenterAlign>
    </S.Container>
  );
}

export default Footer_login;
