import React from 'react';
import { PageContainer, HeaderContainer, Content } from './styles'; // Importando os novos estilos
import Header from '../../components/Header_login';
import Footer_login from '../../components/Footer_login';

function Planos() {
  return (
    <PageContainer>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <Content>
      <iframe 
          src="/pg_planos.html" 
          width="100%" 
          height="500px" 
          title="Sobre o MarketDo"
          style={{ border: 'none' }}
        />       
      </Content>
      <Footer_login />
    </PageContainer>
  );
}

export default Planos;
