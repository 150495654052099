import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
`;

export const Container2 = styled.div`
  height: calc(100vh - 80px); /* Considerando Header e Footer */
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;

  a {
    margin-top: 40px;
    font-weight: bold;
    font-size: 14px;
    color: #03520d;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Input = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  input {
    width: 100%;
    height: 40px;
    font-size: 15px;
    padding: 5px 10px;
    border: 1px solid #03520d;
    border-radius: 8px;
    outline: none;
    transition: border 0.3s ease, box-shadow 0.3s ease;

    &:focus {
      border-color: #024a0c;
      box-shadow: 0px 0px 5px rgba(2, 74, 12, 0.5);
    }
  }
`;

export const Save = styled.div`
  width: 100%;
  max-width: 300px;
  margin-top: 30px;

  button {
    width: 100%;
    background: #03520d;
    border: none;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    padding: 12px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;

    &:hover {
      background: #024a0c;
      transform: translateY(-2px);
    }

    &:focus {
      outline: 2px dashed #03520d;
      outline-offset: 4px;
    }
  }
`;
