import React from 'react';
import { PageContainer, HeaderContainer, Content } from './styles';
import Header from '../../components/Header_login';
import Footer_login from '../../components/Footer_login';
import PgSobre from './PgSobre'; // Importando o componente convertido

function Sobre() {
  return (
    <PageContainer>
      <HeaderContainer>
        <Header /> {/* Garante que os botões do Header sejam renderizados */}
      </HeaderContainer>
      <Content>
        <PgSobre /> {/* Renderizando o componente React diretamente */}
      </Content>
      <Footer_login />
    </PageContainer>
  );
}

export default Sobre;
