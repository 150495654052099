import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 50px; /* Aumentei a altura para melhorar o espaçamento visual */
  background: #ffffff;
  border-top: 5px solid #cf0505;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px; /* Aumentei o espaçamento nas laterais para 40px */
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); /* Adiciona uma leve sombra para destacar o rodapé */

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    height: auto; /* Permite que a altura se ajuste conforme o conteúdo */
    padding: 10px 20px; /* Ajuste para melhorar o espaçamento em dispositivos móveis */
  }
`;

export const SpanLeftAlign = styled.span`
  text-align: left;
  color: #cf0505;
  font-size: 14px;
  font-weight: bold;
  margin-left: 20px; /* Adicionei margem à esquerda para afastar das bordas */

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 5px; /* Adiciona espaçamento inferior em dispositivos móveis */
    margin-left: 0; /* Remove a margem em dispositivos móveis */
  }
`;

export const SpanCenterAlign = styled.span`
  text-align: center;
  color: #cf0505;
  font-size: 14px;
  font-weight: bold;

  @media (max-width: 768px) {
    margin-bottom: 5px; /* Adiciona espaçamento inferior em dispositivos móveis */
  }
`;

export const SpanRightAlign = styled.span`
  text-align: right;
  color: #cf0505;
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px; /* Adicionei margem à direita para afastar das bordas */

  @media (max-width: 768px) {
    text-align: center;
    margin-right: 0; /* Remove a margem em dispositivos móveis */
  }
`;
