// styles.js (DrawerMenu atualizado para parecer com o exemplo da imagem)
import styled from "styled-components";

export const DrawerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const DrawerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0; /* Alinha o modal à esquerda */
  width: 320px;
  height: 100%;
  background: #f8f8f8; /* Cor de fundo suave para parecer com os cards */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave para dar profundidade */
  border-radius: 12px; /* Bordas arredondadas para o contêiner */
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    width: 100%;
    border-radius: 0; /* Remove arredondamento para dispositivos móveis */
  }
`;

export const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    color: #333333; /* Cor do título mais escura */
    font-weight: bold;
  }

  button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333333; /* Botão de fechar com cor escura */
  }
`;

export const DrawerContent = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 15px;
    text-align: left;
    border: none;
    background: none;
    padding: 0;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const HomeCard = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background: #f1f1f1; /* Cor de fundo suave para os cards */
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave para o card */
  transition: background 0.3s, transform 0.3s;

  &:hover {
    background: #e0e0e0; /* Fundo um pouco mais escuro ao passar o mouse */
    transform: translateX(5px); /* Pequeno movimento para a direita ao passar o mouse */
  }

  img {
    height: 30px; /* Ícone ligeiramente menor */
    width: 30px;
    margin-right: 15px;
  }

  span {
    font-size: 16px;
    font-weight: bold;
    color: #333333; /* Cor do texto mais escura para combinar com a imagem enviada */
  }
`;
