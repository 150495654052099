// Header.js
import React, { useState } from 'react';
import * as S from './styles';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import bell from '../../assets/bell.png';
import isConnected from '../../utils/isConnected';
import { FormattedMessage } from 'react-intl';
import secureStorage from '../../utils/SecureStorage';
import { MdMenu } from 'react-icons/md'; // Novo ícone de menu
import DrawerMenu from '../../components/DrawerMenu'; // Importando o DrawerMenu

function Header({ clickNotification }) {
  const [lateCount] = useState(0); // Inicializando com 0
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para abrir/fechar o menu

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  async function Logout() {
    secureStorage.clear();
    window.location.reload();
  }

  return (
    <>
      <S.Container>
        <S.RightSide>
          {/* Ícone de Menu no Header */}
          <button type="button" onClick={toggleMenu}>
            <MdMenu size={24} color="#cf0505" /> {/* Ícone de menu */}
          </button>
          <span className="dividir" />
          {lateCount > 0 && (
            <>
              <button onClick={clickNotification}>
                <img src={bell} alt="Notificação" />
                <span>{lateCount}</span>
              </button>
              <span className="dividir" />
            </>
          )}
        </S.RightSide>
        <S.LeftSide>
          <img src={logo} alt="Logo" />
        </S.LeftSide>
      </S.Container>
      {isMenuOpen && <DrawerMenu closeDrawer={toggleMenu} />} {/* Renderiza o DrawerMenu se estiver aberto */}
    </>
  );
}

export default Header;
