// styles.js (estilos do componente Header)
import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  border-bottom: 5px solid #cf0505;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px; 
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: row-reverse;
    align-items: center;
    padding: 10px 0;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 35px;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-end;
    padding-right: 10px;

    img {
      height: 30px;
    }
  }
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  padding-right: 10px;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
    padding-left: 10px;
  }

  a,
  button {
    color: #cf0505;
    font-weight: bold;
    text-decoration: none;
    margin: 0 10px;

    &:hover {
      opacity: 0.7;
    }
  }

  .dividir {
    margin: 0 10px;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      height: 20px;
      margin-right: 5px;
    }

    span {
      color: #cf0505;
      font-weight: bold;
    }
  }
`;

export const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 20px;

  img {
    height: 25px;
    width: 25px;
  }

  &:hover {
    opacity: 0.7;
  }
`;
