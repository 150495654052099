import styled from "styled-components";

// Tema centralizado para cores e espaçamento
const colors = {
  primary: "#ffffff",
  secondary: "#f9f9f9",
  shadow: "rgba(0, 0, 0, 0.1)",
  text: "#333",
};

const spacing = {
  small: "10px",
  medium: "20px",
  large: "40px",
};

// Contêiner da página
export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Garante que a página ocupe toda a altura da tela */
  width: 100%;
  background-color: ${colors.primary};
`;

// Cabeçalho
export const HeaderContainer = styled.header`
  width: 100%;
  background-color: ${colors.primary};
  box-shadow: 0px 4px 8px ${colors.shadow};
  padding: ${spacing.small} 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;

  h1 {
    font-size: 1.5rem;
    color: ${colors.text};
    text-align: center;
    margin: 0;
  }

  p {
    font-size: 1rem;
    color: ${colors.text};
    text-align: center;
    margin: ${spacing.small} 0 0;
  }
`;

// Conteúdo Principal
export const Content = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${spacing.medium};
  background-color: ${colors.secondary};

  @media (max-width: 768px) {
    padding: ${spacing.small};
  }
`;

// Cartões de Contato (exemplo para extensibilidade)
export const ContactCard = styled.div`
  background-color: ${colors.primary};
  box-shadow: 0px 2px 6px ${colors.shadow};
  border-radius: 8px;
  padding: ${spacing.medium};
  margin: ${spacing.small};
  width: 100%;
  max-width: 300px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 50px;
    margin-bottom: ${spacing.small};
  }

  .contact-title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: ${spacing.small};
    color: ${colors.text};
  }

  .contact-link {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #0056b3;
    }
  }
`;

export default {
  PageContainer,
  HeaderContainer,
  Content,
  ContactCard,
};
