import React from 'react';
import './PgSobre.css'; // Estilos específicos do componente

function PgSobre() {
  return (
    <>
      {/* Cabeçalho */}
      <header className="pg-sobre-header">
        <h1>
          A solução completa para transformar a gestão e otimização do seu merchandising!
        </h1>
        <p>
          Conquiste resultados com mais agilidade e eficiência! Automatize tarefas, colete dados
          precisos e facilite a rotina dos seus promotores.
        </p>
      </header>

      {/* Seção de Benefícios */}
      <main>
        <section className="benefits" id="benefits">
          <div className="benefit-item">
            <div className="benefit-icon" aria-hidden="true">📅</div>
            <div className="benefit-title">Planejamento Inteligente</div>
            <p>Agende visitas e organize atividades com poucos cliques.</p>
          </div>
          <div className="benefit-item">
            <div className="benefit-icon" aria-hidden="true">📊</div>
            <div className="benefit-title">Dados em Tempo Real</div>
            <p>Monitore desempenho direto do seu painel de controle.</p>
          </div>
          <div className="benefit-item">
            <div className="benefit-icon" aria-hidden="true">📈</div>
            <div className="benefit-title">Relatórios Personalizados</div>
            <p>Gere insights valiosos e compartilhe com facilidade.</p>
          </div>
          <div className="benefit-item">
            <div className="benefit-icon" aria-hidden="true">⚙️</div>
            <div className="benefit-title">Produtividade Elevada</div>
            <p>Reduza tarefas manuais e ganhe mais tempo para estratégias.</p>
          </div>
        </section>

        {/* Seção de Chamada para Ação */}
        <section className="cta-section">
          <p>Descubra como o MarketDo pode transformar a gestão do seu time! 🚀</p>
          <a href="/FaleConosco" className="cta-button">
            Fale Conosco
          </a>
        </section>
      </main>
    </>
  );
}

export default PgSobre;
